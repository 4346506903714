/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstallmentPlanDto } from '../models/InstallmentPlanDto';
import type { LoanConfirmCreateRequest } from '../models/LoanConfirmCreateRequest';
import type { LoanCustomerHistoryRequest } from '../models/LoanCustomerHistoryRequest';
import type { LoanDocumentRemoveRequest } from '../models/LoanDocumentRemoveRequest';
import type { LoanDocumentResponse } from '../models/LoanDocumentResponse';
import type { LoanDocumentUploadConfirmRequest } from '../models/LoanDocumentUploadConfirmRequest';
import type { LoanDocumentUploadUrlRequest } from '../models/LoanDocumentUploadUrlRequest';
import type { LoanDraftCreateRequest } from '../models/LoanDraftCreateRequest';
import type { LoanPatchRequest } from '../models/LoanPatchRequest';
import type { LoanSearchRequest } from '../models/LoanSearchRequest';
import type { PaginatedInstallmentPlanDto } from '../models/PaginatedInstallmentPlanDto';
import type { StepUpdateLoanDraftApproveLoanRequest } from '../models/StepUpdateLoanDraftApproveLoanRequest';
import type { StepUpdateLoanDraftBack } from '../models/StepUpdateLoanDraftBack';
import type { StepUpdateLoanDraftConfirmDownPaymentRequest } from '../models/StepUpdateLoanDraftConfirmDownPaymentRequest';
import type { StepUpdateLoanDraftContractDocumentRequest } from '../models/StepUpdateLoanDraftContractDocumentRequest';
import type { StepUpdateLoanDraftCustomerDocumentRequest } from '../models/StepUpdateLoanDraftCustomerDocumentRequest';
import type { StepUpdateLoanDraftCustomerInfoRequest } from '../models/StepUpdateLoanDraftCustomerInfoRequest';
import type { StepUpdateLoanDraftDeviceAndLoanRequest } from '../models/StepUpdateLoanDraftDeviceAndLoanRequest';
import type { StepUpdateLoanDraftRejectLoanRequest } from '../models/StepUpdateLoanDraftRejectLoanRequest';
import type { UploadConfirmResponse } from '../models/UploadConfirmResponse';
import type { UploadUrlResponse } from '../models/UploadUrlResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LoanService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns LoanDocumentResponse OK
     * @throws ApiError
     */
    public getContractDownloadUrl({
        loanId,
    }: {
        loanId: string,
    }): CancelablePromise<LoanDocumentResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/{loanId}/generate-contract',
            path: {
                'loanId': loanId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedInstallmentPlanDto OK
     * @throws ApiError
     */
    public searchLoans({
        requestBody,
    }: {
        requestBody: LoanSearchRequest,
    }): CancelablePromise<PaginatedInstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public createLoanDraft({
        requestBody,
    }: {
        requestBody: LoanDraftCreateRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/draft',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftRejectLoan({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftRejectLoanRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/draft/reject-loan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftDeviceAndLoan({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftDeviceAndLoanRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/draft/loan-and-device',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftCustomerInfo({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftCustomerInfoRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/draft/customer-info',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftCustomerDocument({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftCustomerDocumentRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/draft/customer-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftContractDocument({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftContractDocumentRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/draft/contract-document',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftConfirmDownPayment({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftConfirmDownPaymentRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/draft/confirm-down-payment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftBack({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftBack,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/draft/back',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public stepUpdateLoanDraftApproveLoan({
        requestBody,
    }: {
        requestBody: StepUpdateLoanDraftApproveLoanRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/draft/approve-loan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadUrlResponse OK
     * @throws ApiError
     */
    public getLoanDocumentUploadUrl({
        requestBody,
    }: {
        requestBody: LoanDocumentUploadUrlRequest,
    }): CancelablePromise<UploadUrlResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/document-upload-url',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadConfirmResponse OK
     * @throws ApiError
     */
    public confirmLoanDocumentUpload({
        requestBody,
    }: {
        requestBody: LoanDocumentUploadConfirmRequest,
    }): CancelablePromise<UploadConfirmResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/document-upload-confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedInstallmentPlanDto OK
     * @throws ApiError
     */
    public getLoanCustomerHistory({
        requestBody,
    }: {
        requestBody: LoanCustomerHistoryRequest,
    }): CancelablePromise<PaginatedInstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/customer-history',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public confirmCreateLoan({
        requestBody,
    }: {
        requestBody: LoanConfirmCreateRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/loans/confirm-create-loan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public patchLoan({
        requestBody,
    }: {
        requestBody: LoanPatchRequest,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v2/loans',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public getLoanById({
        loanId,
    }: {
        loanId: string,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/loans/{loanId}',
            path: {
                'loanId': loanId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public removeLoanDocument({
        requestBody,
    }: {
        requestBody: LoanDocumentRemoveRequest,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/loans/document-remove',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
