/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type InstallmentPayment = {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    termNumber?: number;
    amount?: number;
    scheduleDate?: string;
    status?: InstallmentPayment.status;
    loanId?: string;
    shopId?: string;
};
export namespace InstallmentPayment {
    export enum status {
        PENDING = 'PENDING',
        ACTIVE = 'ACTIVE',
        CLOSED = 'CLOSED',
        INACTIVE = 'INACTIVE',
        PAID = 'PAID',
    }
}

