/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DevicePatchRequest } from './DevicePatchRequest';
import type { ProfileDto } from './ProfileDto';
export type LoanPatchRequest = {
    referenceId?: string;
    loanId: string;
    status?: LoanPatchRequest.status;
    shopId: string;
    customer?: ProfileDto;
    device?: DevicePatchRequest;
    installmentPlanFundSource?: LoanPatchRequest.installmentPlanFundSource;
    downPaymentAmount?: number;
    downPaymentDate?: string;
    installmentCount?: number;
    installmentAmount?: number;
    installmentStartDate?: string;
    installmentFrequency?: number;
    installmentFrequencyType?: LoanPatchRequest.installmentFrequencyType;
    commissionAmount?: number;
    loanDocumentRemoveIds?: Array<string>;
    loanDocumentObjectKeys?: Array<string>;
};
export namespace LoanPatchRequest {
    export enum status {
        DRAFT = 'DRAFT',
        PENDING_CONTRACT_DOCUMENTS = 'PENDING_CONTRACT_DOCUMENTS',
        PENDING_CONFIRM_DOWN_PAYMENT = 'PENDING_CONFIRM_DOWN_PAYMENT',
        NORMAL = 'NORMAL',
        CLOSED = 'CLOSED',
        REFUND = 'REFUND',
        FOLLOWING_UP = 'FOLLOWING_UP',
        CANNOT_CONTACT_CUSTOMER = 'CANNOT_CONTACT_CUSTOMER',
        NON_PERFORMING = 'NON_PERFORMING',
        REPORTED = 'REPORTED',
        CANCEL = 'CANCEL',
    }
    export enum installmentPlanFundSource {
        SHOP = 'SHOP',
        FINANCE = 'FINANCE',
    }
    export enum installmentFrequencyType {
        DAY = 'DAY',
        MONTH = 'MONTH',
    }
}

