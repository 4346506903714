import { Box, Button, Card, CardContent, Divider, List, ListItem, ListItemButton, ListItemText, Pagination, Stack, TextField, Typography } from "@mui/material";
import { useMotalClient2 } from "../client/motal-apis";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
// import { LoanEvent } from "../client/motal-api/generated";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LoanResponse } from "../client/motal-apis/generated";
import toast from "react-hot-toast";
import { InstallmentPlanDto } from "../client/motal-apis/generated";


export const LoanScreen = () => {
    const [page, setPage] = useState<number>(1)

    const motalClient = useMotalClient2()
    const [searchText, setSearchText] = useState<string>('')
    const [selectedLoan, setSelectedLoan] = useState<InstallmentPlanDto | null>(null)
    const loanQuery = useQuery({ queryKey: ['loans', searchText, page], queryFn: () => motalClient.admin.searchLoans1({ requestBody: { page: page, searchText: searchText.length >= 3 ? searchText : undefined } }) })
    const shopQuery = useQuery({ queryKey: ['shop', selectedLoan?.installmentPlan?.shopId], queryFn: () => motalClient.admin.getShopById1({ shopId: selectedLoan?.installmentPlan?.shopId || '' }), enabled: !!selectedLoan?.installmentPlan?.shopId })
    const calculateButtonMutation = useMutation({
        mutationFn: () => motalClient.admin.calculateLoanInstallment({
            requestBody: { shopId: selectedLoan?.installmentPlan?.shopId || '', loanId: selectedLoan?.installmentPlan?.id || '' }
        }),
        onSuccess: (data) => {
            toast.success('บันทึกสำเร็จ')
        }
    })

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
                <Box sx={{ backgroundColor: 'white', zIndex: 2, p: 2, width: '100%', boxShadow: 1 }}>
                    <TextField
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        sx={{ backgroundColor: 'white' }}
                        variant="outlined"
                        placeholder="ค้าหา"
                        fullWidth
                    />
                </Box>
                <Stack direction='row' mt={1}>
                    <Box width={280} sx={{ p: 2, backgroundColor: 'white', boxShadow: 1, width: 280, minHeight: '90vh' }}>
                        {loanQuery.isFetching ? <Typography>loading...</Typography> : <Stack direction='column' >
                            <List>
                                <ListItem>
                                    <Typography>Total: {loanQuery.data?.pagination?.totalElements?.toLocaleString()}</Typography>
                                </ListItem>
                                <Divider />
                                <Pagination sx={{ my: '10px' }} page={page} count={loanQuery.data?.pagination?.totalPages || 1} onChange={(e, n) => { setPage(n) }} />
                                <Divider />

                                {
                                    loanQuery.data && loanQuery.data.content?.map((loan, index) => (
                                        <>
                                            <ListItem key={loan.installmentPlan?.referenceId} disablePadding>
                                                <ListItemButton onClick={() => setSelectedLoan(loan)}>
                                                    <Stack width={'100%'}>
                                                        <Stack sx={{ width: '100%' }} display={'flex'} direction={"row"} justifyContent={'space-between'} alignItems={'center'} >
                                                            <ListItemText primary={shopQuery.data?.name} />
                                                            {/* <Typography >{loan.status}</Typography> */}

                                                            {/* <CircleIcon sx={{ color: loan.status === LoanEvent.status. ? 'green' : 'orange' }} /> */}
                                                        </Stack>
                                                        {/* <Typography >{loan.status}</Typography> */}

                                                        {/* <ListItemText primary={`${} ${}`} /> */}
                                                        <ListItemText primary={`${loan.customer?.firstName} ${loan.customer?.lastName}`} />
                                                    </Stack>
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider />
                                        </>
                                    ))
                                }
                            </List>
                        </Stack>
                        }
                    </Box>
                    <Box sx={{ mx: 1, p: 2, backgroundColor: 'white', boxShadow: 1, width: { sm: `calc(100% - 280px)` } }}>
                        {selectedLoan && <Box sx={{ p: 2 }}>
                            <Card variant="outlined" sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">Loan</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Transaction Date: {selectedLoan.installmentPlan?.createdAt}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Status: {selectedLoan.installmentPlan?.status}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Total Amount: {(selectedLoan.installmentPlan?.totalAmount || 0).toLocaleString()} THB
                                    </Typography>
                                    {/* <Typography variant="body2" color="text.secondary">
                                        Total Remaining Amount: {(selectedLoan.totalRemainingAmount||0)?.toLocaleString()} THB
                                    </Typography> */}
                                </CardContent>
                            </Card>

                            <Card variant="outlined" sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">Shop</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Name: {shopQuery.data?.name || '-'}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Address: {shopQuery.data?.address || '-'}
                                    </Typography>
                                    {/* <Typography variant="body2" color="text.secondary">
                                        Phone Number: {selectedLoan.shop. || '-'}
                                    </Typography> */}
                                    <Typography variant="body2" color="text.secondary">
                                        Facebook: {shopQuery.data?.facebookUrl || '-'}
                                    </Typography>
                                </CardContent>
                            </Card>

                            <Card variant="outlined" sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">Customer</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Name: {selectedLoan.customer?.firstName} {selectedLoan.customer?.lastName} {!selectedLoan.customer?.firstName && !selectedLoan.customer?.lastName && '-'}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Phone Number: {selectedLoan.customer?.phoneNumbers?.find(e => true)?.phoneNumber || '-'}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Email: {selectedLoan.customer?.email || '-'}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Address: {selectedLoan.customer?.citizenAddress || '-'}
                                    </Typography>
                                </CardContent>
                            </Card>

                            <Card variant="outlined" sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">Device</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Brand: {selectedLoan.device?.brand || '-'}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Model: {selectedLoan.device?.model || '-'}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Color: {selectedLoan.device?.color || '-'}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        IMEI: {selectedLoan.device?.imei || '-'}
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Button disabled={calculateButtonMutation.isPending} onClick={() => calculateButtonMutation.mutate()} variant="contained">คำนวณงวดสินเชื่อ</Button>
                        </Box>}
                    </Box>
                </Stack>
            </LocalizationProvider>
            {/* <Box sx={{ visibility: (selectedLoan) ? 'visible' : 'hidden', p: 2, bgcolor: 'white', width: { xs: '100%', sm: 'calc(100% - 240px)' }, position: 'fixed', bottom: 0, zIndex: 99 }} >
                <Stack direction='row' gap={1} justifyContent='center'>
                    <Button variant='outlined' size='large'
                        sx={{
                            width: {
                                xs: '100%', sm: '100%', md: 200
                            },
                        }}>ยกเลิก</Button>
                    <Button variant='contained' size='large'
                        sx={{
                            width: {
                                xs: '100%', sm: '100%', md: 200
                            },
                        }}>บันทึก</Button>
                </Stack>
            </Box > */}
        </>
    )
} 