import { Add } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";

export const SearchHeader = ({
  searchTerm,
  setSearchTerm,
  setPage,
  handleOpenNewDeviceDialog,
}: {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  setPage: (page: number) => void;
  handleOpenNewDeviceDialog: () => void;
}) => {
  return (
    <div
      style={{
        padding: "16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <TextField
        label="Search devices or brands"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => {
          setPage(0);
          setSearchTerm(e.target.value);
        }}
        style={{ width: "300px" }}
      />
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={handleOpenNewDeviceDialog}
      >
        Add New Device
      </Button>
    </div>
  );
};
