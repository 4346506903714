import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Inbox as InboxIcon,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query"; // Assuming you are using react-query
import { useState } from "react";
import toast from "react-hot-toast";
import { useMotalClient2 } from "../client/motal-apis";
import { MasterDeviceDto } from "../client/motal-apis/generated/models/MasterDeviceDto";
import { EditDeviceDialog } from "../components/MasterDevice/EditDeviceDialog";
import { NewDeviceDialog } from "../components/MasterDevice/NewDeviceDialog";
import { SearchHeader } from "../components/MasterDevice/SearchHeader";
import { useDebouncedValue } from "../utils/hooks/useDebouncedValue";
// At this point we know they all came from our database so id should always be present
type DefinitelyExistDevice = Omit<MasterDeviceDto, "id"> & { id: string };

export default function DeviceList() {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 250);
  const [openNewDeviceDialog, setOpenNewDeviceDialog] = useState(false);
  const [newDevice, setNewDevice] = useState({
    displayName: "",
    brandName: "",
  });
  const [deviceToEdit, setDeviceToEdit] =
    useState<DefinitelyExistDevice | null>(null);
  const clientv2 = useMotalClient2();

  const {
    data: devices,
    refetch: devicesRefetch,
    isLoading,
  } = useQuery({
    queryKey: [
      "devices",
      {
        page,
        rowsPerPage,
        debouncedSearchTerm,
      },
    ],
    queryFn: () =>
      clientv2.admin.getMasterDevices({
        query: searchTerm,
        page,
        limit: rowsPerPage,
      }),
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const queriedDevices = (devices?.data ?? []) as DefinitelyExistDevice[];

  const { mutateAsync: createDevice } = useMutation({
    mutationFn: (req: { requestBody: MasterDeviceDto }) =>
      clientv2.admin.createMasterDevice({ requestBody: req.requestBody }),
  });

  const { mutateAsync: deleteDevice } = useMutation({
    mutationFn: (req: { id: string }) =>
      clientv2.admin.deleteMasterDevice({ id: req.id }),
  });

  const { mutateAsync: updateDevice } = useMutation({
    mutationFn: (req: { id: string; requestBody: MasterDeviceDto }) =>
      clientv2.admin.updateMasterDevice({
        id: req.id,
        requestBody: req.requestBody,
      }),
  });

  const handleEdit = (device: (typeof queriedDevices)[number]) => {
    setDeviceToEdit(device);
  };

  const toastSuccess = (msg: string) => {
    toast.success(msg);
  };

  const handleSaveEdit = async (device: DefinitelyExistDevice) => {
    try {
      await updateDevice({
        id: device.id,
        requestBody: {
          brandName: device.brandName,
          displayName: device.displayName,
        },
      });
      await devicesRefetch();
      setDeviceToEdit(null);
      toastSuccess("Device updated successfully");
    } catch (error) {
      console.error("Failed to update device:", error);
    }
  };

  const handleCloseEditDevice = () => {
    setDeviceToEdit(null);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteDevice({ id });
      await devicesRefetch();
      toastSuccess("Device deleted successfully");
    } catch (error) {
      console.error("Failed to delete device:", error);
    }
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
    devicesRefetch();
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    devicesRefetch();
  };

  const handleOpenNewDeviceDialog = () => {
    setOpenNewDeviceDialog(true);
  };

  const handleCloseNewDeviceDialog = () => {
    setOpenNewDeviceDialog(false);
    setNewDevice({ displayName: "", brandName: "" });
  };

  const handleAddNewDevice = async () => {
    try {
      await createDevice({ requestBody: newDevice });
      handleCloseNewDeviceDialog();
    } catch (error) {
      console.error("Failed to create device:", error);
    }
  };

  return (
    <Paper>
      <SearchHeader
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setPage={setPage}
        handleOpenNewDeviceDialog={handleOpenNewDeviceDialog}
      />
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={400}
        >
          <CircularProgress />
        </Box>
      ) : queriedDevices.length === 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height={400}
        >
          <InboxIcon style={{ fontSize: 64, color: "rgba(0, 0, 0, 0.3)" }} />
          <Typography variant="h6" color="textSecondary" mt={2}>
            No devices found
          </Typography>
          <Typography variant="body2" color="textSecondary" mt={1}>
            Try adjusting your search or add a new device
          </Typography>
        </Box>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Display Name</TableCell>
                  <TableCell>Brand Name</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {queriedDevices.map((device) => (
                  <TableRow key={device.id}>
                    <TableCell>{device.displayName}</TableCell>
                    <TableCell>{device.brandName}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => handleEdit(device)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(device.id)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {queriedDevices.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={devices?.total ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
      {deviceToEdit && (
        <EditDeviceDialog
          deviceToEdit={deviceToEdit}
          setDeviceToEdit={(v) => setDeviceToEdit(v)}
          handleCloseEditDevice={handleCloseEditDevice}
          handleSaveEdit={handleSaveEdit}
        />
      )}
      <NewDeviceDialog
        openNewDeviceDialog={openNewDeviceDialog}
        handleCloseNewDeviceDialog={handleCloseNewDeviceDialog}
        newDevice={newDevice}
        setNewDevice={setNewDevice}
        handleAddNewDevice={handleAddNewDevice}
      />
    </Paper>
  );
}
