/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaymentDocumentResponse } from './PaymentDocumentResponse';
export type PaymentResponse = {
    shopId?: string;
    shopPaymentChannelId?: string;
    customerCitizenId?: string;
    customerPassportNumber?: string;
    loanId?: string;
    installmentTermNumber?: number;
    note?: string;
    appointmentAt?: string;
    transactionAt?: string;
    appointmentAmount?: number;
    amount?: number;
    fineAmount?: number;
    totalAmount?: number;
    status?: PaymentResponse.status;
    type?: PaymentResponse.type;
    documents?: Array<PaymentDocumentResponse>;
};
export namespace PaymentResponse {
    export enum status {
        SUCCESS = 'SUCCESS',
        CANCEL = 'CANCEL',
        PENDING = 'PENDING',
        COMPLETED = 'COMPLETED',
        OVERDUE = 'OVERDUE',
    }
    export enum type {
        DOWN_PAYMENT = 'DOWN_PAYMENT',
        TERM_PAYMENT = 'TERM_PAYMENT',
        REPAYMENT_PARTIAL = 'REPAYMENT_PARTIAL',
        REPAYMENT_FULL = 'REPAYMENT_FULL',
        FINE = 'FINE',
        CLOSED = 'CLOSED',
    }
}

