/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PhoneNumber } from './PhoneNumber';
export type ShopDto = {
    id?: string;
    name: string;
    address: string;
    lineId?: string;
    facebookUrl: string;
    phoneNumbers: Array<PhoneNumber>;
    status?: ShopDto.status;
    expireAt?: string;
    avatarUrl?: string;
    shopUserRole?: ShopDto.shopUserRole;
};
export namespace ShopDto {
    export enum status {
        ACTIVE = 'ACTIVE',
        WAITING_FOR_APPROVE = 'WAITING_FOR_APPROVE',
        EXPIRED = 'EXPIRED',
        SUSPENDED = 'SUSPENDED',
    }
    export enum shopUserRole {
        OWNER = 'OWNER',
        STAFF = 'STAFF',
    }
}

