/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceCreateRequest } from './DeviceCreateRequest';
import type { ProfileDto } from './ProfileDto';
export type LoanConfirmCreateRequest = {
    referenceId?: string;
    loanId: string;
    shopId: string;
    customer: ProfileDto;
    device: DeviceCreateRequest;
    installmentPlanFundSource: LoanConfirmCreateRequest.installmentPlanFundSource;
    downPaymentAmount: number;
    downPaymentDate: string;
    installmentCount: number;
    installmentAmount: number;
    installmentStartDate: string;
    installmentFrequency: number;
    installmentFrequencyType: LoanConfirmCreateRequest.installmentFrequencyType;
    commissionAmount?: number;
    loanDocumentObjectKeys?: Array<string>;
};
export namespace LoanConfirmCreateRequest {
    export enum installmentPlanFundSource {
        SHOP = 'SHOP',
        FINANCE = 'FINANCE',
    }
    export enum installmentFrequencyType {
        DAY = 'DAY',
        MONTH = 'MONTH',
    }
}

