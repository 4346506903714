/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaymentCreateRequest } from '../models/PaymentCreateRequest';
import type { PaymentDocumentUploadConfirmRequest } from '../models/PaymentDocumentUploadConfirmRequest';
import type { PaymentDocumentUploadUrlRequest } from '../models/PaymentDocumentUploadUrlRequest';
import type { PaymentResponse } from '../models/PaymentResponse';
import type { UploadConfirmResponse } from '../models/UploadConfirmResponse';
import type { UploadUrlResponse } from '../models/UploadUrlResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PaymentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns UploadUrlResponse OK
     * @throws ApiError
     */
    public getPaymentDocumentUploadUrl({
        requestBody,
    }: {
        requestBody: PaymentDocumentUploadUrlRequest,
    }): CancelablePromise<UploadUrlResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/payments/document-upload-url',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadConfirmResponse OK
     * @throws ApiError
     */
    public confirmPaymentDocumentUpload({
        requestBody,
    }: {
        requestBody: PaymentDocumentUploadConfirmRequest,
    }): CancelablePromise<UploadConfirmResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/payments/document-upload-confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaymentResponse OK
     * @throws ApiError
     */
    public createPayment({
        requestBody,
    }: {
        requestBody: PaymentCreateRequest,
    }): CancelablePromise<PaymentResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/payments/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
