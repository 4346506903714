import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

export function EditDeviceDialog<
  T extends { displayName: string; brandName: string }
>({
  deviceToEdit,
  setDeviceToEdit,
  handleCloseEditDevice,
  handleSaveEdit,
}: {
  deviceToEdit: T | null;
  setDeviceToEdit: (device: T) => void;
  handleCloseEditDevice: () => void;
  handleSaveEdit: (device: T) => void;
}) {
  return (
    <Dialog open={Boolean(deviceToEdit)} onClose={handleCloseEditDevice}>
      <DialogTitle>Edit Device</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Display Name"
          fullWidth
          value={deviceToEdit!.displayName}
          onChange={(e) =>
            setDeviceToEdit({
              ...deviceToEdit!,
              displayName: e.target.value,
            })
          }
        />
        <TextField
          margin="dense"
          label="Brand Name"
          fullWidth
          value={deviceToEdit!.brandName}
          onChange={(e) =>
            setDeviceToEdit({ ...deviceToEdit!, brandName: e.target.value })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseEditDevice}>Cancel</Button>
        <Button onClick={() => handleSaveEdit(deviceToEdit!)} color="primary">
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
