/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstallmentPlanDto } from '../models/InstallmentPlanDto';
import type { ShopDto } from '../models/ShopDto';
import type { ShopPaymentChannelResponse } from '../models/ShopPaymentChannelResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns ShopDto OK
     * @throws ApiError
     */
    public getShopPublicProfile({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<ShopDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/shops/{shopId}',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopPaymentChannelResponse OK
     * @throws ApiError
     */
    public getShopPublicPaymentChannels({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<Array<ShopPaymentChannelResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/shops/{shopId}/payment-channels',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns InstallmentPlanDto OK
     * @throws ApiError
     */
    public getPublicLoanById({
        loanId,
    }: {
        loanId: string,
    }): CancelablePromise<InstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/shops/loans/{loanId}',
            path: {
                'loanId': loanId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJob(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-users',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJobMigrateShops(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-shops',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJobMigratePayments(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-payments',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJobMigrateLoans(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-loans',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public triggerJobMigrateCustomers(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/v2/migrate/migrate-customers',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
