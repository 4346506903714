import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

export const NewDeviceDialog = ({
  handleAddNewDevice,
  handleCloseNewDeviceDialog,
  newDevice,
  openNewDeviceDialog,
  setNewDevice,
}: {
  openNewDeviceDialog: boolean;
  handleCloseNewDeviceDialog: () => void;
  newDevice: { displayName: string; brandName: string };
  setNewDevice: (newDevice: { displayName: string; brandName: string }) => void;
  handleAddNewDevice: () => void;
}) => {
  return (
    <Dialog open={openNewDeviceDialog} onClose={handleCloseNewDeviceDialog}>
      <DialogTitle>Add New Device</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Display Name"
          fullWidth
          value={newDevice.displayName}
          onChange={(e) =>
            setNewDevice({ ...newDevice, displayName: e.target.value })
          }
        />
        <TextField
          margin="dense"
          label="Brand Name"
          fullWidth
          value={newDevice.brandName}
          onChange={(e) =>
            setNewDevice({ ...newDevice, brandName: e.target.value })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseNewDeviceDialog}>Cancel</Button>
        <Button onClick={handleAddNewDevice} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
