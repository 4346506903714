/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type InstallmentPlan = {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    mongoId?: string;
    shopId?: string;
    customerProfileId?: string;
    productId?: string;
    registrationDate?: string;
    registrationStatus?: InstallmentPlan.registrationStatus;
    registrationRejectReason?: string;
    sequenceNumber?: number;
    referenceId?: string;
    status?: InstallmentPlan.status;
    fundSource?: InstallmentPlan.fundSource;
    activeTermNumber?: number;
    activeTermDueDate?: string;
    commissionAmount?: number;
    totalAmount?: number;
    downPaymentAmount?: number;
    downPaymentDate?: string;
    installmentTotalAmount?: number;
    installmentCount?: number;
    installmentAmount?: number;
    installmentStartDate?: string;
    installmentFrequency?: number;
    installmentFrequencyType?: InstallmentPlan.installmentFrequencyType;
};
export namespace InstallmentPlan {
    export enum registrationStatus {
        PENDING_CUSTOMER_INFO = 'PENDING_CUSTOMER_INFO',
        PENDING_CUSTOMER_DOCUMENTS = 'PENDING_CUSTOMER_DOCUMENTS',
        PENDING_LOAN_AND_DEVICE_INFO = 'PENDING_LOAN_AND_DEVICE_INFO',
        PENDING_APPROVAL = 'PENDING_APPROVAL',
        APPROVED = 'APPROVED',
        REJECTED = 'REJECTED',
        CANCEL = 'CANCEL',
    }
    export enum status {
        DRAFT = 'DRAFT',
        PENDING_CONTRACT_DOCUMENTS = 'PENDING_CONTRACT_DOCUMENTS',
        PENDING_CONFIRM_DOWN_PAYMENT = 'PENDING_CONFIRM_DOWN_PAYMENT',
        NORMAL = 'NORMAL',
        CLOSED = 'CLOSED',
        REFUND = 'REFUND',
        FOLLOWING_UP = 'FOLLOWING_UP',
        CANNOT_CONTACT_CUSTOMER = 'CANNOT_CONTACT_CUSTOMER',
        NON_PERFORMING = 'NON_PERFORMING',
        REPORTED = 'REPORTED',
        CANCEL = 'CANCEL',
    }
    export enum fundSource {
        SHOP = 'SHOP',
        FINANCE = 'FINANCE',
    }
    export enum installmentFrequencyType {
        DAY = 'DAY',
        MONTH = 'MONTH',
    }
}

